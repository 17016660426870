<template>
  <el-card class="iot-dtu iot-search-header public-page-style">
    <div slot="header">
      <el-form :inline="true" :model="getForm">
          <el-form-item>
            <el-date-picker
      v-model="getForm.time"
      type="date"
      size="small"
      @change="getOperationLog()"
      :picker-options="pickerOptions"
      value-format="yyyy-MM-dd"
      placeholder="选择日期">
    </el-date-picker>
     </el-form-item>
       
          <el-form-item label="">
          <el-select
            v-model="getForm.createId"
            filterable
            size="small"
            placeholder="请选择"
            @change="getOperationLog"
          >
            <el-option
              v-for="item in userArr"
              :key="item.userId"
              :label="item.username"
              :value="item.userId"
            >
            </el-option>
          </el-select>
          </el-form-item>
          <el-form-item>
          <el-button size="mini" type="primary" plain @click="getOperationLog()"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div style="height:650px; overflow: auto;">
         <el-table
      :data="tableData"
      stripe
      border=""
      class="dtu-tab"
      :header-cell-style="{ background: theme.TABLE_HEADER_COLOR }"
      max-height="650"
    >
      <el-table-column
        prop="operationTime"
        label="操作时间"
        width="160"
       
      ></el-table-column>
      <el-table-column
        prop="username"
        label="执行账户"
        width="160"
       
      ></el-table-column>
  <el-table-column width="150" label="客户端类型">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.clientType == 1"
            >安卓</el-tag
          >
          <el-tag type="primary" v-else-if="scope.row.clientType == 2"
            >苹果</el-tag
          >
          <el-tag type="primary" v-else-if="scope.row.clientType == 3"
            >电脑端</el-tag
          >
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="ip"
        label="IP地址"
        width="160"
       
      ></el-table-column>
      <el-table-column
        prop="addr"
        label="地址"
        overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
      ></el-table-column> -->
      <el-table-column
        prop="moduleType"
        label="操作板块"

      ></el-table-column>
  <el-table-column
        prop="functionType"
        label="动作"
      
      ></el-table-column>
      <el-table-column
        prop="params"
        label="内容"
        width="500px"
      ></el-table-column>
    </el-table>
    </div>
 
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="getForm.current"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="getForm.size"
      :total="getForm.total"
      layout="total, sizes, prev, pager, next, jumper"
      style="text-align: right; margin-top: 10px; margin-right: 10px"
    ></el-pagination>
  </el-card>
</template>
<script>
import { TABLE_HEADER_COLOR } from "../util/theme";
let that;
export default {
  name: "CreateDtu",
  data() {
    return {
      theme: { TABLE_HEADER_COLOR },
      tableData: [],
      click:true,
      userArr:[],
      getForm: {
        size: 10,
        current: 1,
        time:"",
        total:0,
        createId:"",
        },
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() > Date.now();
          },
          },
      };
  
  },
  methods: {
      getUserNoPage: function () {
      that.$api.projectGroup.userNoPage().then((response) => {
        if (200 == response.code) {
          that.userArr = response.data;
          that.$set(that.getForm, "createId", response.data[0].userId);
          that.getOperationLog();
        }
      });
    },
    getTime(){
      let date=new Date();
      let month =
      date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1;
      let dates = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      that.getForm.time=date.getFullYear() + "-" + month + "-" + dates;
    },
    handleSizeChange(val) {
      that.$set(that.getForm, "size", val);
      that.$set(that.getForm, "current", 1);
      that.getOperationLog(that.getForm);
    },
    handleCurrentChange(val) {
      that.$set(that.getForm, "current", val);
      that.getOperationLog(that.getForm);
    },

    getOperationLog() {
      if (that.click) {
        that.click=false;
        setTimeout(() => {
          that.click=true;
        }, 1000);
         that.$api.operationLog.getOperationLog(that.getForm).then((response) => {
        if (response.code == 200) {
          that.tableData = response.data.records;
          that.getForm.total = response.data.total;
        }
      });
      }
     
    }, 
  },
  beforeCreate() {
    that = this;
  },
  mounted() {
    that.getTime();
    that.getUserNoPage()
    
    // that.getOperationLog();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-select,
.el-input,
.el-input-number {
  width: 250px;
 
}
.el-input-number >>> input {
  text-align: left;
}
.iot-dtu-create {
  float: right;
  margin-top: 8px;
}
.iot-dtu-permission {
  height: 387px;
}
.iot-search-header >>> .el-card__header {
  padding: 0 18px;
}
.iot-dtu >>> .iot-step-body {
  width: 100%;
  height: calc(100% - 40px);
  padding: 30px;
}

.iot-dtu >>> .el-dialog__body {
  width: 95%;
  height: 90%;
  overflow: hidden;
}

.dtu-tab {
  overflow-x: auto;
}
.iot-dtu >>> .el-form-item {
  vertical-align: middle;
}
</style>
